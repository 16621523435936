import React from "react";
import "./Ev.css";
import {
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import evfinal from "./assets/evfinal.png";
import circle1 from "./assets/circle1.png";
import circle2 from "./assets/circle2.png";
import circle3 from "./assets/circle3.png";
import circle4 from "./assets/circle4.png";
import mob1 from "./assets/mob1.png";
import mob2 from "./assets/mob2.png";
import mob3 from "./assets/mob3.png";
import socket from "./assets/socket.png";
import indus from "./assets/indus.png";
import type2 from "./assets/type2.png";
import class1 from "./assets/class1.png";
import charging from "./assets/charging.png";
import backup from "./assets/backup.png";
import indicate from "./assets/indicate.png";

import safety from "./assets/safety.png";
import uses from "./assets/uses.png";
import carnetwork from "./assets/carnetwork.png";
import { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "./General/Constant";
function Ev(props) {
  const evChargerList = props.evChargerList;
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [product, setProduct] = useState('')

  const getNumber = (val) => {
    setNumber(val.target.value)
  };
  const getEmail = (val) => {
    setEmail(val.target.value)
  };
  const getProduct = (val) => {
    setProduct(val);
  };
  const sendEnquiry = (val) => {
    if (product) {
      if (!email) alert('Email Required')
      else {
        axios.post(API_URL + '/inquiry/', {
          number: number,
          email: email,
          product: product
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true)
              alert("Message Send, we will get back to you as soon as possible!");
          })

          .catch(function (error) { });
      }
    }
    else alert('Something went Wrong')
  };
  return (
    <div className="ev">
      <div className="w-full bg-gradient-to-r to-[#03A14C] from-[#50BE5B] mt-18 background-image">
        <div className="title-banner">
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="banner-main-div">
                <h2>
                  Electric Vehicle <br />
                  <span className="text-gray-800">Charging System</span>
                </h2>

                <div className="text-md">
                Share the Power Plug-in Your EV - AC to AC Charging, Expand Your Charging Options.
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img src={evfinal} alt="" className="main-img top-margin" />
            </Col>
          </Row>
        </div>
      </div>

      <br />
      <div className="pop">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 py-16 mb-4">
          <div className="flex items-center flex-col justify-center mx-4 my-8 ">
            <img src={circle1} alt="/" />
            <h6 className="">ELECTRIC VEHICLE CHARGERS</h6>
            <p>
              {" "}
              Smart EV chargers with internet connectivity for 2, 3 and 4
              wheelers.
            </p>
          </div>

          <div className="flex items-center flex-col justify-center px-4">
            <h6 className="">CMS+OCPP SUPPORT</h6>
            <p>
              {" "}
              We provide solutions for both Private and Public charging using
              Cloud Integrated CMS.
            </p>
            <img src={circle2} alt="/" />
          </div>

          <div className="flex items-center flex-col  justify-center px-4">
            <img src={circle3} alt="/" />
            <h6 className="pt-2">CONSUMER APP SUPPORT</h6>
            <p>
              {" "}
              Navigate to your nearest charging point with ourAEIDTH CHARGE
              mobile app to pre book your charging spot.
            </p>
          </div>

          <div className="flex items-center  flex-col  justify-center px-4">
            <img src={circle4} alt="/" />
            <h6 className="pt-2">ELECTRIC VEHICLE CHARGERS</h6>
            <p>
              {" "}
              Specially created for Business Management, to manage your
              charging businesses anywhere anytime.
            </p>
          </div>
        </div>
      </div>
<div className="product-partition">
{evChargerList ? <div className="product-div">
        <div className="flex">
          <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4"></div>
          <h4 className="uppercase font-bold ">AC EV Products</h4>
          <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
        </div>
        <br />
        <Row className="product-row">
          {evChargerList.map((val, i) => (
            <Col className="product-col" md="3" lg="3" xl="3" sm="12" xs="12" key={i}>
              <div className="product-card hover:scale-105 ease-in duration-200 ev-product-card">
                <h4 className="product-heading">{val.name}</h4>
                <img src={val.img} alt={val.name} className="product-img" />
                <hr />
                <div className="product-desc">
                  <span>{val.description}</span>
                  <div className="button-class">
                    <button className="specification-button">
                      <a
                        href={val.pdf}
                        download={val.name}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "whitesmoke",
                        }}
                      >
                        Specification
                      </a>
                    </button>
                    <button
                      id="PopoverFocus"
                      onClick={(e) => getProduct(val.name)}
                      className="specification-button"
                    >
                      Contact Us
                    </button>
                    <UncontrolledPopover
                      placement="bottom"
                      target="PopoverFocus"
                      trigger="legacy"
                    >
                      <PopoverHeader>Product Enquiry</PopoverHeader>
                      <PopoverBody className="pop-up-body">
                        <div className="pop-up-div">
                          <strong>Email Address:</strong><span className="required">(Required)</span>
                          <br />
                          <input onChange={getEmail} type="text"></input>
                          <br />
                          <strong>Mobile Number:</strong>
                          <br />
                          <input onChange={getNumber} type="number"></input>
                          <br />
                          <button
                            onClick={sendEnquiry}
                            className="specification-button pop-up-submit"
                          >
                            Submit
                          </button>
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>
              </div>
              <br />
            </Col>
          ))}
        </Row>
      </div> : ''}
      <br />
</div>
     
      
      <div className="evcharging">
        <div className="text-slate-50 py-4">
          <h3> AEIDTH CHARGE MOBILE APP</h3>
        </div>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="flex flex-col justify-center items-center m-4">
            <img src={mob1} alt="/" />
            <p className="text-slate-800 py-4 mx-4">
              {" "}
              Locate your current location <br />
              in the app screen and <br /> let the app find a nearest <br />{" "}
              EV charger for you
            </p>
          </div>
          <div className="flex flex-col justify-center items-center m-4">
            <img src={mob2} alt="/" />
            <p className="text-slate-800 py-4 mx-4">
              Click on the nearest EV charger <br /> location pointed on your{" "}
              <br /> and go to the destination
            </p>
          </div>
          <div className="flex flex-col justify-center items-center m-4">
            <img src={mob3} alt="/" />
            <p className="text-slate-800 py-4 mx-4">
              Now you can start <br /> charging through Aeidth <br />
              mobile app
            </p>
          </div>
        </div>
      </div>
      <div className="socket">
        <Row>
          <Col
            style={{
              textAlign: "left",
              paddingLeft: "4rem",
              paddingTop: "2rem",
            }}
          >
            <Row>
              <span
                style={{
                  fontSize: "40px",
                  fontWeight: "800",
                  color: "white",
                }}
              >
                EV CHARGER SOCKET{" "}
              </span>
              <br />
              <span
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "medium",
                }}
              >
                {" "}
                Our Chargers supports variety of vehicles like E-Rickshaw,
                E-Cars and E-bikes. <br />
                With the variety of vehicles we also provide diverse connector
                types that are used in the modern EV's.
              </span>
            </Row>
            <Row>
              <Col className="ind">
                <img src={indus} alt="/" />
                <div className="data">
                  <span style={{ fontSize: "18px", color: "white" }}>
                    Industrial Socket{" "}
                  </span>{" "}
                  <br />
                  No of Pins: 3<br />
                  Max Power: 3.3kW
                  <br />
                  Current Type: AC Available
                </div>
              </Col>
              <Col>
                <div className="type">
                  <img src={type2} alt="/" />
                  <div className="data">
                    <span style={{ fontSize: "18px", color: "white" }}>
                      TYPE-2{" "}
                    </span>{" "}
                    <br />
                    No of Pins: 7Max <br />
                    Power: 21kW <br />
                    Current Type:AC Available
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "40px",
                textAlign: "center",
                color: "white",
                paddingBottom: "30px",
              }}
            >
              <Col>
                <img src={class1} alt="/" /> <br />
                <span>
                  {" "}
                  class-1 <br />
                  charging <br /> solutions
                </span>
              </Col>
              <Col>
                <img src={charging} alt="/" /> <br />
                Auto <br />
                charging
              </Col>
              <Col>
                <img src={backup} alt="/" /> <br />
                Battery <br />
                backup
              </Col>
              <Col>
                <img src={indicate} alt="/" /> <br />
                Charging <br />
                Indications
              </Col>
            </Row>
          </Col>
          <Col>
            <div className="socketimage">
              <img src={socket} alt="" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="h-fit w-screen bg-salte-50 py-8">
        <div className="flex justify-center items-center">
          <div className="h-fit w-fit py-2 px-8 bg-[#03A14C] rounded-full">
            <div className="text-2xl text-slate-50  uppercase">
              our charging network
            </div>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="flex items-center justify-center">
          <img className="m-2" src={carnetwork} alt="/" />
        </div>
        <div className="flex items-center flex-col justify-center">
          <img src={safety} alt="/" />
          <img src={uses} alt="/" />
        </div>
      </div>
    </div>
  );
}

export default Ev;
