import React from "react";
import BatteryCharger from "./assets/battery-charger.png";
import iotfeature from "./assets/iotfeature.png";
import Protection from "./assets/protection.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Table, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import axios from "axios";
import { API_URL } from "./General/Constant";
const Bc = (props) => {
  const { pathname } = useLocation();
  const batteryChargerList = props.batteryChargerList
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [product, setProduct] = useState('')

  const getNumber = (val) => {
    setNumber(val.target.value)
  };
  const getEmail = (val) => {
    setEmail(val.target.value)
  };
  const getProduct = (val) => {
    setProduct(val);
  };
  const sendEnquiry = (val) => {
    if (product) {
      if (!email) alert('Email Required')
      else {
        axios.post(API_URL + '/inquiry/', {
          number: number,
          email: email,
          product: product
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true)
              alert("Message Send, we will get back to you as soon as possible!");
          })
          .catch(function (error) { });
      }
    }
    else alert('Something went Wrong')
  };
  useEffect(() => {

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="bmspage">
      <div className="w-full bg-gradient-to-r to-[#03A14C] from-[#50BE5B] mt-18 background-image">
        <div className="title-banner">
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="banner-main-div">
                <h2>
                  Battery <br />
                  <span className="text-gray-800">Charger</span>
                </h2>
                <div>
                Next-Gen and Smart Charging Unleashed, with Reliable AC to DC Performance.
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img
                src={BatteryCharger}
                alt="BatteryCharger"
                className="main-img top-margin"
              />
            </Col>
          </Row>
        </div>
      </div>
  
      <div className="product-partition">
      {batteryChargerList ? <div className="product-div">
        <div className="flex">
          <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4">
          </div>
          <h4 className="uppercase font-bold ">Battery Charger Products</h4>
          <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
        </div>
        <br />
        <div className="product-a-list">
      {batteryChargerList.map((product, index) => (
        <div className="product-a-card" key={index}>
          <img src={product.img} alt={product.name} className="product-a-image" />
          <h2 className="product-a-title">{product.name}</h2>
          <p className="product-a-description">{product.description}</p>
          <div className="button-a-group">
          <button className="specification-button">
                        <a
                          href={product.pdf}
                          download={product.name}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "white"
                          }}
                        >
                          Specification
                        </a>
                      </button>
                      <button
                        id="PopoverFocus"
                        onClick={(e) => getProduct(product.name)}
                        className="specification-button"
                      >
                        Contact Us
                      </button>
          </div>
        </div>
      ))}
    </div>
        {/* <Row className="product-row">
          {batteryChargerList.map((val, i) => (
            <Col className="product-col" md="3" lg="3" xl="3" sm="12" xs="12" key={i}>
              <div className="product-card hover:scale-105 ease-in duration-200 bc-product-card">
                <h4 className="product-heading">{val.name}</h4>
                <img src={val.img} alt={val.name} className="product-img" />
                <hr />
                <div className="product-desc">
                  <span>{val.description}</span>
                  <div className="button-class">
                    <button className="specification-button">
                      <a
                        href={val.pdf}
                        download={val.name}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "whitesmoke",
                        }}
                      >
                        Specification
                      </a>
                    </button>
                    <button
                      id="PopoverFocus"
                      onClick={(e) => getProduct(val.name)}
                      className="specification-button"
                    >
                      Contact Us
                    </button>
                    <UncontrolledPopover
                      placement="bottom"
                      target="PopoverFocus"
                      trigger="legacy"
                    >
                      <PopoverHeader>Product Enquiry</PopoverHeader>
                      <PopoverBody className="pop-up-body">
                        <div className="pop-up-div">
                          <strong>Email Address:</strong><span className="required">(Required)</span>
                          <br />
                          <input onChange={getEmail} type="text"></input>
                          <br />
                          <strong>Mobile Number:</strong>
                          <br />
                          <input onChange={getNumber} type="number"></input>
                          <br />
                          <button
                            onClick={sendEnquiry}
                            className="specification-button pop-up-submit"
                          >
                            Submit
                          </button>
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>
              </div>
              <br />
            </Col>
          ))}
        </Row> */}
      </div> : ''}
      <br />
      </div>
      <div className="bg-[#80CFA2] my-4">
        <div className="py-4">
          <div className="h-14 w-4/12 bg-[#D7EADF] capitalize mt-4 text rounded-r-full flex items-center justify-center">
            <div className="md:text-2xl md:font-bold text-lg"> features</div>
          </div>
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className=" flex items-center  justify-center py-4">
                <img src={iotfeature} alt="" style={{ margin: "8rem 0" }} />
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="h-fit rounded-xl bg-[#D2F0DF] text-left my-8 mr-8">
                <ul className="py-4 lg:text-lg xs:text-xs lg:mx-4  lg:font-medium">
                  <li className="py-[0.3rem]">CAN 2.0B Enabled</li>
                  <li className="py-[0.3rem]">RoHS Compliant</li>
                  <li className="py-[0.3rem]">AIS 156 Compliant</li>
                  <li className="py-[0.3rem]">
                    Supports NMC/LFP/LTO Battery Chemistry
                  </li>
                  <li className="py-[0.3rem]">
                    Multiple Charging Modes Based of Battery Chemistry
                  </li>
                  <li className="py-[0.3rem]">&gt; 92% Efficiency</li>
                  <li className="py-[0.3rem]">Pre Charge</li>
                  <li className="py-[0.3rem]">LED Indications</li>
                  <li className="py-[0.3rem]">CC/CV Mode</li>
                  <li className="py-[0.3rem]">Tapper Modes</li>
                  <li className="py-[0.3rem]">Special Mode for LFP</li>
                  <li className="py-[0.3rem]">Compact Design</li>
                  <li className="py-[0.3rem]">GFCI</li>
                  <li className="py-[0.3rem]">PFC</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>

        <div className="py-4">
          <div className="h-14 w-4/12 bg-[#D7EADF]  capitalize  text rounded-r-full  flex items-center justify-center">
            <div className=" "> Specifications</div>
          </div>
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="table-div" style={{ height: '95%' }}>
                <h6 style={{ backgroundColor: "#D2F0DF", textAlign: "center" }}>
                  Output
                </h6>
                <Table bordered responsive>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>DC Voltage</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>48V - 84V</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>Current</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>10A - 40A</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Voltage Ripple
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>&lt; 1%</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Current Ripple
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>&lt; 3%</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Line Regulation
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>± 2%</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Load Regulation
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>± 2%</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>Mode</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        PreCharge/CC/CV
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>Connector</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        AS PER REQUIREMENT
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="table-div" style={{ height: '95%' }}>
                <h6 style={{ backgroundColor: "#D2F0DF", textAlign: "center" }}>
                  Input
                </h6>
                <Table bordered responsive>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Line Frequency
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>47 - 63 HZ</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Mains Low Cut Off
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        120 ± 5 VAC
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Low Cut Off Recovery
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        140 ± 5 VAC
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Mains High Cut Off
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        280 ± 5 VAC
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        High Cut Off Recovery
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        260 ± 5 VAC
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Surge L-N / L-E
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>4KV</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>Connector</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>3 PIN PLUG</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="bg-[#80CFA2] my-4">
        <div className="py-4">
          <div className="h-14 w-4/12 bg-[#D7EADF] capitalize mt-4 text rounded-r-full flex items-center justify-center">
            <div className="md:text-2xl md:font-bold text-lg">Protections</div>
          </div>

          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="table-div">
                <div className="h-fit rounded-xl bg-[#D2F0DF] text-left my-8 mr-8">
                  <ul className="py-4 lg:text-lg xs:text-xs lg:mx-4  lg:font-medium">
                    <li className="py-[0.3rem]">Over Current Protection</li>
                    <li className="py-[0.3rem]">Under Voltage Protection</li>
                    <li className="py-[0.3rem]">Short Circuit Protection</li>
                    <li className="py-[0.3rem]">Over Temperature Protection</li>
                    <li className="py-[0.3rem]">
                      Battery Reverse Polarity Protection
                    </li>
                    <li className="py-[0.3rem]">Open Load Protection</li>
                    <li className="py-[0.3rem]">Ground Fault Protection</li>
                    <li className="py-[0.3rem]">AC Over Voltage Protection</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className=" flex items-center  justify-center py-4 mr-2">
                <img src={Protection} alt="" style={{ width: "10rem" }} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Bc;
