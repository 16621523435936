import React from "react";

import cable from "./assets/cable.png";
import evpng from "./assets/evpng.png";
import bike from "./assets/bike.png";
import carev from "./assets/carev.png";
import iot from "./assets/iot.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="bmspage">
      <div className="w-full bg-gradient-to-r to-[#17C165] from-[#066F36] mt-18 ">
        <div className="home-banner">
          <Row>
            <Col md="5" lg="5" xl="5" sm="12" xs="12">
              <div className=" text-left text-white">
                <img className="drop-shadow-lg" src={cable} alt="" />
                <div className="md:px-20 px-8">
                  <h2>
                    AEIDTH <br /> TECHNOLOGIES
                  </h2>
                  <div className="text-xl">
                  CHARGING THE FUTURE OF ELECTRIC MOBILITY. ONE COMPONENT AT A TIME.
                  </div>
                </div>
                <div className="md:px-20 px-8">
                  <Link to="/contact">
                    <button className="h-10 w-40 border-[#004D23] font-medium text-sm border-3 rounded-full my-4 text-[#004D23] bg-slate-50  uppercase hover:bg-[#004D23] hover:text-white">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="7" lg="7" xl="7" sm="12" xs="12">
              <img src={evpng} alt="" style={{padding:"5rem"}} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className=" m-auto grid lg:grid-cols-2 md:grid-cols-1 gap-4 md:py-4">
        
          <div className="py-12 text-left  px-10 ">
          <Link to="/battery-management-system" style={{textDecoration:'none', color:'black'}}>
            <div className="">
              <h2 className="text-[#004D23] md:font-bold">
                Advance & Smart <br />
                Battery Management System
              </h2>
            </div>
            <div className="h-[0.1rem] my-4 bg-black mr-4"></div>
            <div className="text-lg">
            As the world transitions towards sustainable energy solutions, electric two-wheelers (2W), three-wheelers (3W), and energy storage systems (ESS) are gaining immense popularity. These vehicles and energy storage systems rely on advanced and smart Battery Management Systems (BMS) to optimize performance, extend battery life, and enhance safety.
            <br/>
            <br/>
            Advanced BMS technology is designed to monitor and manage various aspects of battery operation, such as state of charge (SoC), state of health (SoH), and state of power (SoP). Through real-time data analysis and precise control algorithms, these systems ensure efficient energy utilization and minimize the risk of overcharging or over-discharging, ultimately enhancing the overall lifespan of the batteries.
            </div>
            </Link>
              {/* <button className="h-10 w-40 border-[#004D23] font-medium text-sm border-3 rounded-full my-4 text-[#004D23] bg-slate-50  uppercase hover:bg-[#004D23] hover:text-white ">
                learn more
              </button> */}
            
          </div>
          <div className="items-center flex justify-center p-4">
            <img src={bike} alt="" />
          </div>
          
        </div>
      </div>
      <div className="w-full bg-[#DBF0E5]">
        <div className=" m-auto grid lg:grid-cols-2 md:grid-cols-1 gap-4 md:py-4">
          <div className="items-center flex justify-center p-4  ">
            <img src={carev} alt="" />
          </div>
          <div className="py-12 text-left  px-10 ">
          <Link to="/electric-vehicle-charger" style={{textDecoration:'none'}}>
            <div className="">
              <h2 className="text-[#004D0F] md:font-bold">
                Electric Vehicle <br />
                Charging Solutions
              </h2>
            </div>
            <div className="h-[0.1rem] my-4 bg-white mr-4"></div>
            <div className="text-lg" style={{color:'#004D0F'}}>
            In the realm of electric vehicle (EV) charging, the spotlight often falls on the efficiency and reliability of AC to DC chargers. These chargers are essential components of the EV infrastructure, converting alternating current from power sources into the direct current needed to recharge batteries. Efficiency ensures minimal energy loss during this conversion, maximizing the miles per charge and minimizing electricity costs. Furthermore, reliability is paramount, as EV owners depend on consistent and dependable charging experiences.
            <br />
            <br />
            Also presenting Indigenous Bharat AC-001 and AC Type-2 EV Chargers that supports all 2W, 3W and 4W Electric Vehicles. Which is highly suitable for the public charging spots like Highway, Metro Parking, Offices and Residential Areas.
            </div>
           
              {/* <button className="h-10 w-40 border-[#004D23] font-medium text-sm border-3 rounded-full my-4 text-[#004D23] bg-slate-50  uppercase hover:bg-[#004D23] hover:text-white ">
                learn more
              </button> */}
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className=" m-auto grid lg:grid-cols-2 md:grid-cols-1 gap-4 md:py-4">
          <div className="py-12 text-left  px-10 ">
          <Link to="/iot-telematics" style={{textDecoration:'none', color:'black'}}>
            <div className="">
              <h2 className="text-[#004D23] md:font-bold">IoT Telematics</h2>
            </div>
            <div className="h-[0.1rem] my-4 bg-black mr-4"></div>
            <div className="text-lg">
            IoT telematics stands a cutting-edge systems leverage the power of interconnected devices and data analytics to enhance the efficiency and convenience of electric vehicles (EVs) and battery swapping networks. IoT telematics solutions enable real-time monitoring of EV performance, battery status, and location, allowing for predictive maintenance and optimized charging strategies.
            </div>
          
              {/* <button className="h-10 w-40 border-[#004D23] font-medium text-sm border-3 rounded-full my-4 text-[#004D23] bg-slate-50  uppercase hover:bg-[#004D23] hover:text-white ">
                learn more
              </button> */}
            </Link>
          </div>
          <div className="items-center flex justify-center p-4 ">
            <img src={iot} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
