import React, { useState } from "react";
import "./Bms.css";
import IoTTelematics from "./assets/iot-telematics.png";
import iotfeature from "./assets/iotfeature.png";
import robot from "./assets/robot.png";

import axios from "axios";
import {
  Row,
  Col,
  Table,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "./General/Constant";
const Iot = (props) => {
  const iotList = props.iotList;
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [product, setProduct] = useState('')

  const getNumber = (val) => {
    setNumber(val.target.value)
  };
  const getEmail = (val) => {
    setEmail(val.target.value)
  };
  const getProduct = (val) => {
    setProduct(val);
  };
  const sendEnquiry = (val) => {
    if (product) {
      if (!email) alert('Email Required')
      else {
        axios.post(API_URL + '/inquiry/', {
          number: number,
          email: email,
          product: product
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true)
              alert("Message Send, we will get back to you as soon as possible!");
          })
          .catch(function (error) { });
      }
    }
    else alert('Something went Wrong')
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="bmspage">
      <div className="w-full bg-gradient-to-r to-[#03A14C] from-[#50BE5B] mt-18 background-image">
        <div className="title-banner">
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="banner-main-div">
                <h2>
                  Internet of Things
                  <br />
                  <span className="text-gray-800">Telematics</span>
                </h2>
                <div>
                EV & Battery Intelligence: Real-Time, Connected & Optimized.
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img src={IoTTelematics} alt="" className="main-img" />
            </Col>
          </Row>
        </div>
      </div>
      <div className="product-partition">
      {iotList ? <div className="product-div">
        <div className="flex">
          <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4"></div>
          <h4 className="uppercase font-bold ">IoT Products</h4>
          <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
        </div>
        <br />
        <div className="product-a-list">
      {iotList.map((product, index) => (
        <div className="product-a-card" key={index}>
          <img src={product.img} alt={product.name} className="product-a-image" />
          <h2 className="product-a-title">{product.name}</h2>
          <p className="product-a-description">{product.description}</p>
          <div className="button-a-group">
          <button className="specification-button">
                        <a
                          href={product.pdf}
                          download={product.name}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "white"
                          }}
                        >
                          Specification
                        </a>
                      </button>
                      <button
                        id="PopoverFocus"
                        onClick={(e) => getProduct(product.name)}
                        className="specification-button"
                      >
                        Contact Us
                      </button>
          </div>
        </div>
      ))}
    </div>
        {/* <Row className="product-row">
          {iotList.map((val, i) => (
            <Col className="product-col" md="3" lg="3" xl="3" sm="12" xs="12" key={i}>
              <div className="product-card hover:scale-105 ease-in duration-200 iot-product-card">
                <h4 className="product-heading">{val.name}</h4>
                <img src={val.img} alt={val.name} className="product-img" />
                <hr />
                <div className="product-desc">
                  <span>{val.description}</span>
                  <div className="button-class">
                    <button className="specification-button">
                      <a
                        href={val.pdf}
                        download={val.name}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "whitesmoke",
                        }}
                      >
                        Specification
                      </a>
                    </button>
                    <button
                      id="PopoverFocus"
                      onClick={(e) => getProduct(val.name)}
                      className="specification-button"
                    >
                      Contact Us
                    </button>
                    <UncontrolledPopover
                      placement="bottom"
                      target="PopoverFocus"
                      trigger="legacy"
                    >
                      <PopoverHeader>Product Enquiry</PopoverHeader>
                      <PopoverBody className="pop-up-body">
                        <div className="pop-up-div">
                          <strong>Email Address:</strong><span className="required">(Required)</span>
                          <br />
                          <input onChange={getEmail} type="text"></input>
                          <br />
                          <strong>Mobile Number:</strong>
                          <br />
                          <input onChange={getNumber} type="number"></input>
                          <br />
                          <button
                            onClick={sendEnquiry}
                            className="specification-button pop-up-submit"
                          >
                            Submit
                          </button>
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>
              </div>
              <br />
            </Col>
          ))}
        </Row> */}
      </div> : ''}
      <br />
</div>
      <div className="bg-[#80CFA2] my-4">
        <div className="py-4">
          <div className="h-14 w-4/12 bg-[#D7EADF] capitalize mt-4 text rounded-r-full flex items-center justify-center">
            <div className="md:text-2xl md:font-bold text-lg"> features</div>
          </div>
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              {" "}
              <div className=" flex items-center  justify-center py-4">
                <img src={iotfeature} alt="" style={{ marginTop: "4rem" }} />
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              {" "}
              <div className="h-fit rounded-xl bg-[#D2F0DF] text-left my-8 mr-8">
                <ul className="py-4 lg:text-lg xs:text-xs lg:mx-4  lg:font-medium">
                  <li className="py-[0.3rem]">CAN 2.0B Enabled</li>
                  <li className="py-[0.3rem]">Live Data Tracking</li>
                  <li className="py-[0.3rem]">On Board Memory</li>
                  <li className="py-[0.3rem]">Low Power Mode</li>
                  <li className="py-[0.3rem]">Over The Air Updates</li>
                  <li className="py-[0.3rem]">In Built Battery Backup</li>
                  <li className="py-[0.3rem]">LED Indications</li>
                  <li className="py-[0.3rem]">Remotely Configured</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="">
          <div className="h-14 w-4/12 bg-[#D7EADF]  capitalize mt-4 text rounded-r-full  flex items-center justify-center">
            <div className="md:text-2xl md:font-bold"> Specifications</div>
          </div>
          <Row>
            <Col md="8" lg="8" xl="8" sm="12" xs="12">
              <div className="table-div">
                <Table bordered responsive style={{ marginTop: "1rem" }}>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#D2F0DF" }}>
                        Parameters
                      </th>
                      <th style={{ backgroundColor: "#D2F0DF" }}>
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>GSM</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        LTE Cat 1 _ IoT Applications, Support 2G & 4G,
                        Worldwide LTE, GSM/GPRS Coverage
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>GNS/GPS</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        For Accurate measurement, Uses Wi-Fi scanning,
                        Accurate 2m, Hot & Cold Sensing
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>SIM</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Standard Sim
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>Antenna</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Cellular Antenna, GNSS Antenna: Internal only
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        CAN Serial Port
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        2.0B High Speed CAN (One for debugging Second for
                        upgrading)
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Internal Storage
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>128 Mbit</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>IO</td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        2 AI, 2 DIO
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Protocols
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        TCP/UDP/PPP/NITZ/PING/FILE /MQTT/NTP/HTTP/HTTPS/SSL
                        /FTP/FTPS/ CMUX/MMS
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Led Indicator
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        CEL, GNSS, Power
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Power Supply
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>6-100V</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Operating Temperature
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        -40 to 85°C
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        Temperature Sensor
                      </td>
                      <td style={{ backgroundColor: "#D2F0DF" }}>
                        -40 to 125°C
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md="4" lg="4" xl="4" sm="12" xs="12">
              <div className=" flex items-center  justify-center py-4 mr-2">
                <img src={robot} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Iot;
