import React from 'react'
import './legal.css'
function RefundPolicy() {
  return (
    <div className="legal-docs">
      <h1>Refund and Cancellation Policy</h1>
      <p>
        Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.<br />
      </p>
      <h3>
        Cancellation Policy
      </h3>
      <p>
        For Cancellations please contact Aeidth Technologies Pvt Ltd via contact us link.
      </p>
      <h3>
        Refund Policy</h3>
      <p>
        Donations made through Aeidth Technologies Pvt Ltd are final and cannot be refunded.
        Any other claims for refunds shall be honored at the discretion of Aeidth Technologies Pvt Ltd Team.<br />
        All refunds will be credited to the original mode of payment in 7-10 bank working days after deducting transaction charges which can be up to 10% of the original transaction.
        Please email at info@aeidth.com in case of any discrepancies.

      </p>
    </div>
  )
}

export default RefundPolicy