import React, { useEffect, useState } from "react";
import "./Info.css";
import "react-phone-input-2/lib/style.css";
import aeidthwhite from "./assets/aeidthwhite.png";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { API_URL } from "./General/Constant";
import { useLocation } from "react-router-dom";
function Info() {
  document.getElementById('cal-overlay').style.display= 'none'
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const postInquiry = () => {
    if (!fullName) alert("missing fullName");
    else if (!number) alert("missing number");
    else if (!email) alert("missing email");
    else if (!message) alert("missing message");
    else {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          email: email,
          message: message,
          mobile: number,
          name: fullName,
        }),
      };
      fetch(API_URL + "/inquiry/", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let status = JSON.parse(result).status;
          if (status === true)
            alert("Message Send, we will get back to you as soon as possible!");
        })
        .catch((error) => console.log("error", error));
    }
  };
  const getName = (value) => {
    value.preventDefault();
    setFullName(value.target.value);
  };
  const getNumber = (value) => {
    value.preventDefault();
    setNumber(value.target.value);
  };
  const getEmail = (value) => {
    value.preventDefault();
    setEmail(value.target.value);
  };
  const getMessage = (value) => {
    value.preventDefault();
    setMessage(value.target.value);
  };

  return (
    <div className="w-full lg:h-fit background-image" style={{color:"white"}}>
      <div className="contact-banner">
      <div className="w-full mx-auto pb-8 pt-24 px-2 max-w-[1240px] ">
        <h3 className="py-4">Get In Touch</h3>
        <div className="grid lg:grid-cols-5 gap-8">
          <div className="col-span-3 lg:col-span-2 w-full h-full shadow-lg shadow-gray-400 rounded-xl p-4">
            <div className="lg:p-2 h-full rounded-xl">
              <div>
                <img src={aeidthwhite} alt="" />
              </div>
              <div className="text-left">
                <p>
                Aeidth Technologies is a company in the field of electric mobility providing charging and battery management solutions for electric vehicle and ESS.
                </p>
                <div className="pt-2">
                  <h6 className="pt-4 pb-2  uppercase font-medium">
                    Contact us
                  </h6>
                  <div>+91-7835932526 </div>
                  <div>+91-8178702117</div>
                  <div>info@aeidth.com</div>
                  <div className="py-2">
                    Ground Floor, B-145, Block B, Phase-2, Noida, Uttar Pradesh
                    PIN: 201301
                  </div>
                </div>
              </div>

              <div className="pt-12">
                <h6 className="uppercase text-left">
                  connect with us
                </h6>
                <div className="flex items-center py-2">
                  <div className="rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-120 ease-in duration-300" style={{margin:"0 1rem"}}>
                    <a href="https://twitter.com/AeidthT" target="_blank" rel="noreferrer">
  
                      <BsTwitter className="text-slate-50" />
                    </a>
                  </div>
                  <div className="rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-120 ease-in duration-300">
                    <a
                      href="https://www.linkedin.com/company/aeidth/mycompany/"
                      target="_blank" rel="noreferrer"
                    >
  
                      <BsLinkedin className="text-slate-50" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-lg shadow-slate-100 h-auto w-full rounded-xl lg:p-4 col-span-3">
            <div className="py-2">
              <div className="flex flex-col">
                <label className="py-2 uppercase  text-sm mx-1">
                  Name
                </label>
                <input
                  className="contact-input border-2 rounded-lg flex p-2 mx-1 border-[#004D23]"
                  type="text"
                  value={fullName}
                  onChange={(e) => getName(e)}
                />
              </div>
              <div className="flex flex-col">
                <label className="py-2 uppercase  text-sm mx-1">
                  Phone Number
                </label>
                <input
                  className="contact-input border-2 rounded-lg flex p-2 mx-1 border-[#004D23]"
                  type="text"
                  onChange={(e) => getNumber(e)}
                  value={number}
                />
              </div>
              <div className="flex flex-col py-2">
                <label className="uppercase  text-sm py-2">
                  Email
                </label>
                <input
                  className="contact-input border-2 rounded-lg flex p-2 mx-1 border-[#004D23]"
                  type="text"
                  onChange={(e) => getEmail(e)}
                  value={email}
                />
              </div>
              <div className="flex flex-col py-2">
                <label className="text-sm uppercase  py-2">
                  message
                </label>
                <textarea
                  className="contact-input border-2 rounded-lg p-2 border-[#004D23]"
                  rows="8"
                  onChange={(e) => getMessage(e)}
                  value={message}
                ></textarea>
              </div>
              <button
                className="uppercase p-2 rounded-lg mt-4 bg-[#004D23] text-gray-100"
                onClick={postInquiry}
              >
                send message
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
export default Info;
