import { useEffect, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import aeidthlogo from "./assets/aeidthlogo.png";
import { Link } from "react-router-dom";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header className="fixed-top ">
      <Link className="logo" to="/">
        <img src={aeidthlogo} alt="logo" />
      </Link>
      <nav className="lg:text-md" ref={navRef}>
        <Link to="/battery-management-system" onClick={showNavbar}>
          Battery Management System
        </Link>
        <Link to="/battery-charger" onClick={showNavbar}>
          Battery Charger
        </Link>
        <Link to="/iot-telematics" onClick={showNavbar}>
          IoT Telematics
        </Link>
        <Link to="/electric-vehicle-charger" onClick={showNavbar}>
          AC EV Charging
        </Link>
        <Link to="/contact" onClick={showNavbar}>
          Contact Us
        </Link>
        <button className="nav-btn nav-close-btn">
          <div className="close" onClick={showNavbar}>
            <FaTimes />
          </div>
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
