import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import CheckSign from "../../components/assets/check-sign.png";
import BalancerCard from "../../components/assets/50A v5.png";
import BmsTile1 from "../../components/assets/bms-tile-1.png";
import BmsTile2 from "../../components/assets/bms-tile-2.png";
import BmsTile3 from "../../components/assets/bms-tile-3.png";
import BmsTile4 from "../../components/assets/bms-tile-4.png";
import BalancerPdf from "../../components/assets/PDF/balancer-card-specification.pdf";
import downloadmain from "../../components/assets/downloadmain.png";

function BalancerTiles() {
  const bmsArray1 = [
    {
      heading: "6S - 96S",
      subHeading: "Cell Series",
      img: BmsTile1,
    },
    {
      heading: "SOH/SOC",
      subHeading: "Measurement",
      img: BmsTile2,
    },
    {
      heading: "Paralleling",
      subHeading: "Upto 8 Packs",
      img: BmsTile3,
    },
    {
      heading: "AIS 156",
      subHeading: "Compliant",
      img: BmsTile4,
    },
  ];
  const bmsArray2 = [
    "Real Time Data Logging",
    "Passive Cell Balancing",
    "IoT Compliant",
    "Active Paralleling",
    "BLE 5.1",
    "CAN 2.0 B",
    "Digital and Analog I/O",
    "Master-Slave Architecture",
    "Ultra Low Power Dissipation",
    "Cell Voltage Accuracy 1.5mV",
  ];

  return (
    <div>
      <div className="flex">
        <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4"></div>
        <h4 className="uppercase font-bold">Battery Management System</h4>
        <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
      </div>
      <div className="balancer-card-tile">
        <h5>Smart Balancer Card</h5>
        <Row style={{ margin: "1rem" }}>
          <Col md="4" lg="4" xl="4" sm="12" xs="12">
            <div
              style={{
                backgroundColor: "rgba(256,256,256,0.5",
                borderRadius: "1rem",
                padding: "5px",
              }}
            >
              {" "}
              <img
                src={BalancerCard}
                alt=""
                style={{ margin: "2rem auto 0 auto" }}
              ></img>
              <br />
              <p
                style={{
                  padding: "0 1rem",
                  color: "#1150A1",
                  fontWeight: "900",
                }}
              >
                Balancer Cards contains AFE and Controllers to ensure cell
                voltage balancing, runs various algorithms. Paired with either
                Power Distribution Card or Power Control Interface.
              </p>
              {/* <button className="specification-button">
                <Row>
                  <Col>
                    <img src={downloadmain} alt="" />
                  </Col>
                  <Col>
                    <a
                      href={BalancerPdf}
                      download="balancer-specification"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "whitesmoke",
                      }}
                    >
                      Specification
                    </a>
                  </Col>
                </Row>
              </button>{" "} */}
            </div>
          </Col>
          <Col md="4" lg="4" xl="4" sm="12" xs="12">
            <Row>
              {bmsArray1.map((val, i) => (
                <Col md="6" lg="6" xl="6" sm="12" xs="12" key={i}>
                  <div className="bms-tile-box">
                    <Row>
                      <Col>
                        <img
                          src={val.img}
                          alt=""
                          className="bms-tile-img-big"
                        ></img>

                        <br />
                        <strong className="bms-tile-heading">
                          {val.heading}
                        </strong>
                        <br />
                        <strong className="bms-tile-sub-heading">
                          {val.subHeading}
                        </strong>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="4" lg="4" xl="4" sm="12" xs="12">
            <Row>
              {bmsArray2.map((val, i) => (
                <Col md="6" lg="6" xl="6" sm="12" xs="12" key={i}>
                  <div className="bms-tile-box verticle-center-align">
                    <Row>
                      <Col md="3" lg="3" xl="3" sm="3" xs="3">
                        <img
                          src={CheckSign}
                          alt=""
                          className="check-img"
                        ></img>
                      </Col>
                      <Col md="9" lg="9" xl="9" sm="9" xs="9">
                        <strong className="bms-tile-strong">{val}</strong>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BalancerTiles;
