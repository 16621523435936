import './App.css';
import Navbar from '../src/components/Navbar';
import Home from './components/Home';
import Bms from './components/Bms';
import Footer from './components/Footer';
import Info from './components/Info';
import Ev from './components/Ev';
import Iot from './components/Iot';
import Bc from './components/Bc';
import Myfooter from './components/Myfooter';
import { Routes, Route } from "react-router-dom"
import { API_URL } from './components/General/Constant';
import { useEffect, useState } from 'react';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import RefundPolicy from './components/Legal/RefundPolicy';
import TnC from './components/Legal/TnC';
import FAQ from './components/Legal/FAQ';


function App() {
  const [evChargerList,setEvChargerList] = useState()
  const [bmsList,setBmsList] = useState()
  const [batteryChargerList,setBatteryChargerList] = useState()
  const [iotList,setIoTList] = useState()
  useEffect(()=>{
    getEvChargerList()
    getBatteryChargerList()
    getBMSList()
    getIoTList()
  },[])
  function getEvChargerList(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/products/?type=evc", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status)
        setEvChargerList(result.data)
      })
      .catch((error) => console.log("error", error));
  }
  function getBatteryChargerList(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      
    };
    fetch(API_URL + "/products/?type=bc", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status)
        setBatteryChargerList(result.data)
      })
      .catch((error) => console.log("error", error));
  }
  function getBMSList(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      
    };
    fetch(API_URL + "/products/?type=bms", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status)
        setBmsList(result.data)
      })
      .catch((error) => console.log("error", error));
  }
  function getIoTList(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      
    };
    fetch(API_URL + "/products/?type=iot", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status)
        setIoTList(result.data)
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <div className="App">
        <Navbar/>
       <Routes>
        <Route exact path='/' element={ <Home/> } />
        <Route exact path='/electric-vehicle-charger' element={ <Ev evChargerList={evChargerList}/> } />
        <Route exact path='/battery-management-system' element={ <Bms bmsList={bmsList}/> } />
        <Route exact path='/bms' element={ <Bms bmsList={bmsList}/> } />
        <Route exact path='/battery-charger' element={ <Bc batteryChargerList={batteryChargerList}/> } />
        <Route exact path='/iot-telematics' element={ <Iot iotList={iotList}/> } />
        <Route exact path='/contact' element={ <Info/> } />
        <Route exact path='/privacy-policy' element={ <PrivacyPolicy/> } />
        <Route exact path='/refund-policy' element={ <RefundPolicy/> } />
        <Route exact path='/terms-and-conditions' element={ <TnC/> } />
        <Route exact path='/faq' element={ <FAQ/> } />
      </Routes>
      <Myfooter>
         <Footer/>
         </Myfooter>
    </div>
  );
}

export default App;
