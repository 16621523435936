import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Myfooter = ({children}) => {

const[showfooter, setShowfooter]= useState(true);
const location = useLocation();
  
useEffect(() => {
    if(location.pathname=== '/contact')
    {
        setShowfooter(false)
    }
    else{
        setShowfooter(true)
    }

}, [location]);

  return (
    <div>
      {showfooter && children}
    </div>
  );
}

export default Myfooter;
