import React from "react";
import "./Bms.css";
import bms_main1 from "./assets/bms_main1.png";
import wheeler from "./assets/wheeler.png";
import network from "./assets/network.png";
import solar from "./assets/solar.png";
import passenger from "./assets/passenger.png";
import beam from "./assets/beam.png";
import dashboard from "./assets/dashboard.png";
import dashboardfinal from "./assets/dashboardfinal.png";

import { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import BalancerTiles from "./BMSTiles/BalancerTiles";
import PowerTiles from "./BMSTiles/PowerTile";
import axios from "axios";
import { API_URL } from "./General/Constant";
const Bms = (props) => {
  const bmsList = props.bmsList;
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [product, setProduct] = useState('')

  const getNumber = (val) => {
    setNumber(val.target.value)
  };
  const getEmail = (val) => {
    setEmail(val.target.value)
  };
  const getProduct = (val) => {
    setProduct(val);
  };
  const sendEnquiry = (val) => {
    if (product) {
      if (!email) alert('Email Required')
      else {
        axios.post(API_URL + '/inquiry/', {
          number: number,
          email: email,
          product: product
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true)
              alert("Message Send, we will get back to you as soon as possible!");
          })
          .catch(function (error) { });
      }
    }
    else alert('Something went Wrong')
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="bmspage">
      <div className="w-full bg-gradient-to-r to-[#03A14C] from-[#50BE5B] mt-18 background-image">
        <div className="title-banner">
          <Row>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <div className="banner-main-div">
                <h2>
                  Advance & Smart <br />
                  <span className="text-gray-800">
                    Battery Management System
                  </span>
                </h2>
                <div>
                Intelligent Control for Reliable Power. Optimize Battery Lifespan & Performance.
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img src={bms_main1} alt="" className="main-img" />
            </Col>
          </Row>
        </div>
      </div>

      <div className="w-full  bg-[#D2F0DF] py-8 items-center product-partition">
        <BalancerTiles />
        <PowerTiles />
        {bmsList ? <div className="product-div">
          <div className="flex">
            <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4"></div>
            <h4 className="uppercase font-bold ">BMS Products</h4>
            <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
          </div>
          <br />
          <div className="product-a-list">
      {bmsList.map((product, index) => (
        <div className="product-a-card" key={index}>
          <img src={product.img} alt={product.name} className="product-a-image" />
          <h2 className="product-a-title">{product.name}</h2>
          <p className="product-a-description">{product.description}</p>
          <div className="button-a-group">
          <button className="specification-button">
                        <a
                          href={product.pdf}
                          download={product.name}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "white"
                          }}
                        >
                          Specification
                        </a>
                      </button>
                      <button
                        id="PopoverFocus"
                        onClick={(e) => getProduct(product.name)}
                        className="specification-button"
                      >
                        Contact Us
                      </button>
          </div>
        </div>
      ))}
    </div>
          {/* <Row className="product-row">
            {bmsList.map((val, i) => (
              <Col className="product-col" md="3" lg="3" xl="3" sm="12" xs="12" key={i}>
                <div className="product-card  hover:scale-105 ease-in duration-200 bms-product-card">
                  <h4 className="product-heading">{val.name}</h4>
                  <img src={val.img} alt={val.name} className="product-img" />
                  <hr />
                  <div className="product-desc">
                    <span>{val.description}</span>
                    <div className="button-class">
                      <button className="specification-button">
                        <a
                          href={val.pdf}
                          download={val.name}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "white"
                          }}
                        >
                          Specification
                        </a>
                      </button>
                      <button
                        id="PopoverFocus"
                        onClick={(e) => getProduct(val.name)}
                        className="specification-button"
                      >
                        Contact Us
                      </button>
                      <UncontrolledPopover
                        placement="bottom"
                        target="PopoverFocus"
                        trigger="legacy"
                      >
                        <PopoverHeader>Product Enquiry</PopoverHeader>
                        <PopoverBody className="pop-up-body">
                          <div className="pop-up-div">
                            <strong>Email Address:</strong><span className="required">(Required)</span>
                            <br />
                            <input onChange={getEmail} type="text"></input>
                            <br />
                            <strong>Mobile Number:</strong>
                            <br />
                            <input onChange={getNumber} type="number"></input>
                            <br />
                            <button
                              onClick={sendEnquiry}
                              className="specification-button pop-up-submit"
                            >
                              Submit
                            </button>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </div>
                </div>
                <br />
              </Col>
            ))}
          </Row> */}
        </div> : ""}
      </div>
      <div className="bg-gradient-to-b from-[#0cb95c] to-[#688776] mt-4 w-screen h-fit py-8">
        <div className="h-fit mx-20 my-8 rounded-3xl">
          <div className="grid lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2">
              <div
                className="md:py-12 px-4 py-4 md:mx-20"
                style={{ color: "white" }}
              >
                <div className="text-left md:text-2xl text-lg font-bold  py-2">
                  BEAM Mobile App
                </div>
                <div className="text-left">
                  <ul className=" text-sm md:text-lg">
                    <li>
                      BEAM is a smartphone application which remotely analyse
                      and configure our AEIDTH Battery Management System.
                    </li>
                    <li>
                      Provides valuable information of battery pack like State
                      of Charge, State of Health, Voltage Current, Temperature
                      and individual Cell Voltages.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:py-4 flex justify-center items-center">
              <img src={beam} alt="" />
            </div>
          </div>
        </div>
        <div className="h-fit mx-20 mt-12 rounded-3xl ">
          <div className="grid lg:grid-cols-3 gap-4">
            <div className="md:py-4 flex justify-center items-center p-4">
              <img src={dashboard} alt="" />
            </div>
            <div className="lg:col-span-2">
              <div className="md:py-12 px-4 py-4 md:mx-20">
                <div className="text-left md:text-2xl text-lg font-bold text-slate-50 py-2">
                  Configuration Dashboard
                </div>
                <div className="text-left">
                  <ul className="text-slate-50 text-sm md:text-lg">
                    <li>
                      Advanced and detailed analytics of all your BMS through
                      single Dashboard.
                    </li>
                    <li>
                      Alerts of any variance and abnormality in Battery
                      performance.
                    </li>
                    <li>
                      Ease to add or update any configuration through
                      Dashboard.
                    </li>
                    <li>
                      Suggesting corrective measures using Data Science to
                      improve battery life and reduce battery degradation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-[#688776] to-[#595959] w-screen h-fit py-24">
        <div className="gs">
          <div className="h-fit mx-20  rounded-3xl ">
            <div className="grid lg:grid-cols-3 gap-4">
              <div className="lg:col-span-2">
                <div className="md:py-12 px-4 py-4 md:mx-20">
                  <div className="text-left md:text-2xl text-lg font-bold text-slate-50 py-2">
                    BMS Analytics Webapp
                  </div>
                  <div className="text-left">
                    <ul className="text-slate-50 text-sm md:text-lg">
                      <li>
                        Be in control of the configuration of BMS, update
                        settings through our App Anywhere Anytime.
                      </li>
                      <li>
                        Complete analytics and all details of BMS devices in
                        one Web application.
                      </li>
                      <li>
                        OTA(over-the-air) updates and improvement along with
                        feature addition to BMS over Cloud.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="md:py-4 flex justify-center items-center p-4">
                <img src={dashboardfinal} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-fit w-screen py-4 px-8 my-2 ">
        {/* <div className="lg:mx-24 text-sm text-slate-50">
            For better data analysis and battery life prediction based on data.
            So that our BMS can connect to Android & IOS App,Web based page,
            Windows software using our BLE5.1 & IoT devices.
          </div> */}
        <div className=" flex flex-col justify-center items-center  my-2">
          <div className="bg-[#03a14c] rounded-full flex justify-center mx-96 my-10 items-center">
            <div className=" md:text-2xl text-md py-2 px-8 text-slate-50">
              Comes with Smart Connectivity !
            </div>
          </div>
          <div className="connectivityy p-2">
            <img src={network} alt="" />
          </div>
        </div>
      </div>

      <div className="bg-[#80CFA2] my-4">
        <div className="application">
          <div>
            <div className="flex justify-center items-center py-4">
              <div className=" h-0.5 w-6/12 bg-gray-700 rounded-full sm:mx-12  mx-4 "></div>
              <div className="md:h-12 w-64 h-8 bg-[#03a14c] rounded-full flex justify-center items-center">
                <div className=" md:text-2xl text-md  text-slate-50">
                  Applications
                </div>
              </div>
              <div className="sm:mx-12 mx-4  items-left rounded-full h-0.5 w-6/12 bg-gray-700"></div>
            </div>

            <div className="grid grid-cols-2 gap-4 ">
              <div className="m-4">
                <img src={wheeler} alt="/" />
              </div>
              <div className="flex justify-center items-center">
                <div className="h-fit py-2 px-8 bg-[#03a14c] rounded-full text-slate-50 mx-4">
                  <div className="text-sm md:text-xl">
                    Electric Two & Three Wheelers
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <div className="h-fit py-2 px-8  bg-[#03a14c] rounded-full text-slate-50 mx-4">
                  <div className="text-sm md:text-xl">
                    Solar Energy Storage Systems
                  </div>
                </div>
              </div>
              <div className="m-4 flex items-center justify-center">
                <img src={solar} alt="/" />
              </div>

              <div className="m-4">
                <img src={passenger} alt="/" />
              </div>
              <div className="flex justify-center items-center">
                <div className="h-fit py-2 px-8 bg-[#03a14c] rounded-full text-slate-50 mx-4">
                  <div className="text-sm md:text-xl">
                    Passengers & Commercial Vehicles
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // else return <div>Loading...</div>;
};

export default Bms;
