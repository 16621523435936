import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Footer.css";
import aeidthwhite from "./assets/aeidthwhite.png";
import twitter from "./assets/twitter.png";
import linkedin from "./assets/linkedin.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="foot" style={{ backgroundColor: "#004D23"}}>
      <Container style={{ marginTop: "10px" }}>
        <Row style={{ marginBottom: "40px" }}>
          <Col>
            <div className="logofooter">
              <img src={aeidthwhite} alt="/" />
            </div>
            <div className="footerdes">
              Aeidth Technologies is a startup in the field of electric mobility
              providing charging and battery management solutions for electric
              vehicle, supported/incubated by Software Technology Park of India.
            </div>
          </Col>

          <Col className="detail">
            <span style={{ fontWeight: "bold", paddingTop: "30px" }}>
              Contact us
            </span>
            <br />
            +91-8800294365
            <br />
            +91-8178702117
            <br />
            <a
              href="https://mail.google.com/mail/u/0/#inbox?compose=VpCqJZNhDJQnCgHlRbdRJNtWgWxWqNbjLTnfcCpRRPWnQJLRTtLlZbcLlJhmHPKsZjLFXTB"
              target="_blank"
              className="link"
              rel='noreferrer'
            >
              info@aeidth.com
            </a>
            <div className="address">
              <br />
              <span>Aeidth Technologies Private Limited</span> <br />
              Ground Floor, B-145, Block B, Phase-2, Noida, Uttar Pradesh <br />
              PIN: 201301
            </div>
          </Col>

          <Col>
            <div className="privacy">
              <div className="policy">
                <Link to="/privacy-policy" className="link">
                  Privacy Policy
                </Link>
                <br />
                <Link to="/refund-policy" className="link">
                  Refund Policy
                </Link>
                <br />
                <Link to="/terms-and-conditions" className="link">
                  Terms and Conditions
                </Link>
                <br />
                {/* <Link to="faq" className="link">
                  FAQs
                </Link>
                <br /> */}
              </div>
            </div>
            <div className="social">
              <a href="https://twitter.com/AeidthT" target="_blank">
                <img className="social-in" src={twitter} alt="/" />
              </a>
              <a
                href="https://www.linkedin.com/company/aeidth/mycompany/"
                target="_blank"
              >
                <img className="social-in" src={linkedin} alt="/" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footerline"></div>
      <div className="last">
        © 2023, All Rights Reserved. Design and Manufacture in India.
      </div>
    </div>
  );
};

export default Footer;
